<template>
    <TopNavigation step="0" title="Terms and Conditions" />

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 lblTitle text-center py-4">Terms of Service</div>

            <div class="col-12 text-center pb-4">
                <div>UPDATED: April 10, 2023</div>
                <div>EFFECTIVE: April 10, 2023</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">SignOn (END USER AND WEBAPP)‍</div>
                <div class="mb-3"><span class="fw-bold">1. Service Description.</span> SignOn is an electronic signature service which allows Customers to display, deliver, acknowledge, store, and electronically sign documents.‍</div>
                <div class="mb-3"><span class="fw-bold">2. Authentication.</span> A person signing a document via SignOn must either have a SignOn account or have received a request for signature in their email account.‍</div>
                <div class="mb-3"><span class="fw-bold">3. Audit Trails.</span> Documents completed in SignOn include an audit trail that contains information that helps track documents through their life cycles. This information includes, but is not limited to, a unique document ID generated by SignOn, email addresses of the sender and recipient(s), and IP addresses of the sender and recipient(s). The audit trail also includes information, such as date, time, and location, about the following events: when a document is uploaded, viewed, removed, or sent, when a document is signed or declined, when a signer’s email address is updated, when a signer’s access code is authenticated, and when a signature request is canceled.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">SignOn API‍</div>
                <div class="mb-3"><span class="fw-bold">1. Service Description.</span> The SignOn Application Programming Interface ("SignOn API") allows Customers to easily integrate or embed the SignOn electronic signature solution into their applications or workflows, creating a clean, branded, and seamless online experience allowing users to complete agreements or transactions.</div>
                <div class="mb-3"><span class="fw-bold">2. Authentication.</span> To the extent that Customer elects to use the SignOn API to enable embedded features on Customer Properties, Customer is required to authenticate the identity of each signer/end user through email confirmation or such other means that SignOn may approve in its sole discretion. Customer is solely responsible and liable for such authentication and will indemnify, defend and hold SignOn harmless against any claim related to such authentication.‍</div>
                <div class="mb-3"><span class="fw-bold">3. Signature Requests.</span> A "Signature Request" is the transaction that takes place when Customer initiates a new signature process and makes a corresponding call to the SignOn APIs. For example, if Customer calls “signature_request/send” to send out documents for signature, this will constitute one (1) Signature Request. Please note that a single Signature Request can be used to gather signatures from multiple signers.</div>
                <div class="mb-3"><span class="fw-bold">4. API Keys.</span> In order to use the SignOn APIs, Customer must obtain its unique API credentials (an "API Key") via the registration process. Customer is solely responsible for all activity associated with its API Key, regardless of whether it has knowledge of such activity. Customer must not share its API Key with any third party, shall keep such API Key secure, and shall use it as Customer's sole means of accessing the SignOn API.‍</div>
                <div class="mb-3"><span class="fw-bold">5. Transactions.</span></div>
                <div class="mb-3"><span class="fw-bold">a. Limits.</span> Customer can make up to 25 requests per minute for standard API requests. In test mode, Customer can do 10 requests per minute. Collectively the above are "Transaction Limits."</div>
                <div class="mb-3"><span class="fw-bold">b. Rate Limits.</span> SignOn may be required to limit or suspend Customer's use of the SignOn API when such suspension or limits are necessary to prevent harm or liability to other Customers/individuals, or to preserve the security, stability, availability or integrity of the SignOn Services.</div>
                <div class="mb-3"><span class="fw-bold">6. Properties.</span> Only those Customer Properties that have been approved by SignOn may access and use the SignOn API. SignOn reserves the right to reject any Customer Property, for any reason, in its sole discretion, including but not limited to ensure that Customer complies with the Terms and the Acceptable Use Policy. Furthermore, Customer agrees to ensure that the Customer Properties contain terms of service and privacy policies that are consistent with the terms of this Agreement.</div>
                <div class="mb-3"><span class="fw-bold">7. API Restrictions.</span> Customer agrees that it will not (and will not permit any third party to) directly or indirectly: (a) create an API client that functions substantially the same as the SignOn APIs; (b) make any use of the SignOn APIs for any purpose independent of the Customer Properties; (c) misrepresent the source or ownership of the SignOn APIs or remove, obscure, or alter any copyright, trademark or other proprietary rights notices, falsify or delete any author attributions, legal notices or other labels of the origin or source of the SignOn APIs; or (d) interfere with or disrupt the SignOn APIs or the servers or networks providing the SignOn APIs or SignOn Services.</div>
                <div><span class="fw-bold">8. Customer Applications.</span> Customer may use the SignOn APIs to develop applications and/or embedded signing experiences for use by Customer or Customer's clients and their respective end users (collectively "Customer Applications"). Customer shall be solely responsible for the Customer Applications and shall ensure it has: a) provided its customers, clients, and end users with the applicable terms (including privacy terms) that authorize SignOn to provide the SignOn Services hereunder, and b) the proper authority and/or authorization to share user or signer information (including personally identifiable information) with SignOn.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">THIRD PARTY INTEGRATIONS / CONNECTORS‍</div>
                <div class="mb-3"><span class="fw-bold">1. Service Description.</span> Third party integrations help connect the SignOn Services with other services like Google (Gmail, Google Docs, Google Drive, and G Suite), Evernote, Hubspot CRM, Microsoft OneDrive, Oracle, and Slack.</div>
                <div class="mb-3"><span class="fw-bold">2. Eligibility.</span> Customer must be a customer of SignOn and a customer of the service Customer wants to use the integration with. Some integrations may require that Customer approve the use of such service and/or consent to the transfer of Customer's information/data between the SignOn Services and the third party service.‍</div>
                <div class="mb-3"><span class="fw-bold">3. Third Party Content and Products.</span> Customer is solely responsible for the use of such integration or third party service, and ensuring that Customer has the proper rights and permissions to share data between the SignOn Services and the third party services. You understand that the third party integrations may provide Customer with access to third party content and to third party products that may access Customer's instance of the SignOn Services and export, delete or otherwise alter Customer Data (including Customer's Confidential Information).‍</div>
                <div><span class="fw-bold">4. Disclaimer.</span> SignOn does not warrant or directly support third party integrations, third party content, third party products (whether or not these items are designated by SignOn as "powered", "verified" or otherwise) and disclaims all responsibility and liability for these items and their access to the SignOn Services, including their modification, deletion, disclosure or collection of Customer Data.</div>
            </div>

        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'

export default {
    name: 'TermsConditions',
    components: { 
        TopNavigation
    },
    setup() {
        return {}
    }
}
</script>

<style>

</style>